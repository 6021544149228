import React from "react";
import "../styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderBar from "../components/headerBar";
import WelcomeSection from "../components/welcomeSection";
import Helmet from 'react-helmet';
const IndexPage = () => {
  return (

    <div className="container">
      <Helmet title="Joshua Ramnaraine" />
      <HeaderBar />
      <WelcomeSection />
    </div>

  )

}
export default IndexPage
