import React from 'react'
import { Link } from 'gatsby'
import { FaGithub, FaDiscord, FaLinkedin } from "react-icons/fa";
export default function socialLinks() {
    return (
        <div className='socialLinks'>
            <Link to="https://github.com/joshRam0214" target="_blank"><FaGithub /></Link>
            <Link to="https://discordapp.com/users/Rastaman14#2832" target="_blank"><FaDiscord /></Link>
            {/* Add email if possible without error */}
            <Link to="https://www.linkedin.com/in/joshuauramnaraine/" target="_blank"><FaLinkedin /></Link>
        </div>
    )
}
