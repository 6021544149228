import React, { useState } from "react"
import ToolBar from "../components/ToolBar";
import SideBar2 from "../components/SideBar2";
import BackDrop from "../components/BackDrop";
const HeaderBar = () => {
    const [sideBar3, setsideBar] = useState(false);

    const toggleSidebar = () => {
        setsideBar((prevState) => !prevState)
    }

    return (
        <div>
            <ToolBar openSideBar={toggleSidebar} />
            <BackDrop sideBars2={sideBar3} closeSideBar={toggleSidebar} />
            <SideBar2 sideBars2={sideBar3} />
        </div>
    )
}
export default HeaderBar