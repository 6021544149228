import React from 'react'
import { SideBarData } from "./sideBarData";
import image from "../images/joshua.svg";
import SocialLinks from '../components/socialLinks'
export default function SideBar2({ sideBars2 }) {
    return (
        // <div className="SideBarTool">
        <div className={sideBars2 ? "SideBarTool SideBarTool--open" : "SideBarTool"}>
            <h1>Joshua Ramnaraine</h1>
            <img src={image} alt="Josh" />
            <h2 className='font-italic'>Software Engineer</h2>
            {SideBarData.map((val, key) => {
                return (
                    <li key={key} onClick={() => { window.location.pathname = val.link }}>
                        <div >{val.icon}</div>
                        <div>{val.title}</div>
                    </li>
                );
            })}
            
            <div className='socialLinkPadding'>
                <h5 className='d-flex justify-content-center'>Contact Me At</h5>
                <SocialLinks/>
            </div>
            

        </div>
    )
}
