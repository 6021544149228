import React from 'react'
import RickNMorty from "../images/Rick_and_Morty.svg";
import SouthPark from "../images/southPark.svg";
import Suits from "../images/narcos.svg";
import FamilyGuy from "../images/family.svg"
export default function welcomeSection() {
  return (
    <div className='welcomeSectionStyle'>
        <h2 className='font-weight-bold'>Welcome</h2>
        <p className='font-italic font-weight-bold'>I am a final year student studying Software Engineering at Ontario Tech University.</p>
        <hr />
        <div className='aboutPage'>
            <h1 className='font-weight-bold'>About</h1>
            <div className='shadow-lg mt-2 p-3 bg-white rounded aboutMeParagraph'>
                <p >
                    For a long time, I knew that I wanted to participate in a field involving computers.
                    I learned about computer programming in high school and developed an interest in this field.
                    I chose to pursue Software Engineering because of the balance of learning in theoretical and practical applications.
                    <br /><br />
                    Throughout my education, I have gotten the opportunity to learn new skills and experience new things.
                    I previously worked for Canada Revenue Agency as a Software Developer.
                    I improved my analytical and technical skills by designing and developing tools
                    for employees in the organization to automate their workload.
                    I'm always looking for new opportunities that are challenging but rewarding.
                </p>
            </div>
            <hr />
        </div>
        <div className='welcomeStyle2 d-flex flex-column align-items-center p-2'>
          <h2 className='font-weight-bold'>Favourite TV Shows</h2>
          <div className='shadow-lg mt-2 bg-white rounded d-flex flex-row align-items-center showIcons justify-content-center'>
            <img src={RickNMorty} className="imageScale" alt="Rick" />
            <img src={SouthPark} className="imageScale" alt="South" />
            <img src={Suits} className="imageScale" alt="Suits" />
            <img src={FamilyGuy} className="imageScale" alt="Family" />
          </div>
        </div>
    </div>
  )
}
