import React from 'react'
import { FaHome, FaProjectDiagram, FaFileAlt } from "react-icons/fa";
export const SideBarData = [
    {
        title: "Home",
        icon: <FaHome/>,
        link:"/",
    },
    {
        title: "Projects",
        icon: <FaProjectDiagram/>,
        link:"/projects",
    },
    {
        title: "Resume",
        icon: <FaFileAlt/>,
        link:"/resume",
    },
];

